import React, { useEffect, useState} from 'react'
import axios from 'axios'
import { serviceRequest } from '../config/request.config'




export default function useCorporations(){
    const [state, setState] = useState<ICorpsState>({
        corps: [],
        loading: true
    })
    
    
    async function fetchCorps(){
        try{
            const data = (await serviceRequest.get("/corporations")).data as Corportation[]
            setState((oldState: ICorpsState)=> ({...oldState, corps: data, loading: false}))
        } catch(err){
            console.log(error)
            setState((oldState: ICorpsState) => ({...oldState, loading: false}))
        }
    }
    
    function getCorpById(id: number){
        return state.corps.find(corp => corp.id == id)
    }
    
    async function createCorp(email:string, name: string){
        try{
            const data = {
                admin_email: email,
                name: name
            }
            const corpData = await serviceRequest.post("/corporations", data)
            await fetchCorps()
            
            
        } catch(err){
            console.log(err)
            throw new Error(err)
        }
    }
    
    useEffect(() => {
        fetchCorps()
    }, [])
    
    return {
        ...state,
        getCorpById,
        createCorp
    }
    
}

