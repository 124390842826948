import { Grid, Button, Text, Input, FormElement } from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { Delete, PaperPlus, Show, Download, Search } from "react-iconly";
import List from "../components/list.component";
import Page, { ActionBar } from "../components/page.component";
import { useModal } from "../contexts/modal.context";
import { useApp } from "../contexts/state.context";
import { isValidEmail } from "../helpers/input.helper";
import { orderContainsString, stringIsSku, stringToDate, stringToFullDate } from "../helpers/misc.helper";
import useServiceMembers from "../hooks/useServiceMembers.hook";





export default function OrdersRoute(){

    const {orders} = useApp()
    const {state, methods} = orders
    
    const {toggleModal, showModal, newModal} = useModal()

    const [filtered, setFiltered] = useState<Order[]>(state)
    const [searchValue, setSeachValue] = useState("")

    const pageActions = [
        // {
        //     onClick: addOrder,
        //     icon: <PaperPlus />,
        //     color: "success"
        // }
    ]




    function addOrder(){
        showModal("DA IMPLEMENTARE", "" , () => {}, toggleModal)
    }

    function toggleOrderModal(order:Order){
       

        newModal({
            title: `Dettagli ordine ${order.sku}`,
            body: <ModalBody item={order}/>,
            onCancel: () => toggleModal(false)
        })
    }


    function onUserDelete(data:Member){
        console.log("DATA", data)
        showModal("Attenzione!", `Eliminare ${data.display_name} - ${data.email} ? `, methods.deleteMember, toggleModal)
    }

    function handleUserSearch(e:string){
        console.log("change", state)
        methods.updateSearchValue(e)
        
    }


    const ordersFiltered = searchValue.length === 0 ? state : (
        state.filter(order => {
            if(stringIsSku(searchValue)) return order.sku === searchValue
            if(isValidEmail(searchValue)) return order.owner_email === searchValue
            if(searchValue.length === 16) return order.transaction_id === searchValue
            return order.item_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 
        })
    )  

    function mapStateToListItemData(state:Order[]){
        return state.map((order,i) => ({
        value: `${order.item_name} | creato il ${stringToDate(order.created_at)}, ore: ${stringToFullDate(order.created_at).hour}`,
        label: `SKU: ${order.sku} | ${order.owner_email}`,
        data: order,
        key: `l-${i}`,
        actions:[
            {
                onClick: (data) => toggleOrderModal(order),
                icon: <Show />,
                color: "success"
            },
        ]
    }))
    }



    const listElements = mapStateToListItemData(filtered)

    useEffect(() => {

        const ordersFiltered = searchValue.length === 0 ? state : (
            state.filter(order => orderContainsString(order, searchValue))
        )  

        setFiltered(ordersFiltered)
        
        
    }, [searchValue])
    

    return(
        <Page title="Ordini" >
            <ActionBar actions={pageActions} middle={() => <InputSearchBar onChange={(e) => setSeachValue(e.target.value)} onClear={()=>setSeachValue("")} value={searchValue} />}/>
            {searchValue}
            <List elements={listElements}/>
        </Page>
    )
}


interface InputSearchBarProps{
    onClear: () => void,
    onChange: (e:any) => void,
    value:string
}

function InputSearchBar(props:InputSearchBarProps){
    const {orders} = useApp()
    const ref = useRef<HTMLElement>(null!)


    useEffect(() => {
        ref.current.focus()
    }, [props.value])

    return (
        <Grid.Container css={{width: "100%", gap: "$10"}}>
            <Grid css={{flex: 1}}>
                <Input ref={ref} value={props.value} onChange={props.onChange} width="100%" aria-label="Filtra per sku, email, transaction id o tipologia prodotto" placeholder="Filtra per sku, email o tipologia prodotto" />
            </Grid>
            <Grid>
                <Button auto light onClick={props.onClear}><Delete style={{marginRight: 10}} size={"small"} /> Cancella </Button>
            </Grid>
        </Grid.Container>
    )
}




function ModalBody(props:{item:Order}){
    const {item} = props
    const orderDate = stringToFullDate(item.created_at)

    function downloadUrl(url:string){
        const u = new URL(url)
        const imagePath = url + "?attachment=true"
        // const image = await saveAs(imagePath, "image.png")

        const a = document.createElement("a")
        a.setAttribute("href", imagePath)
        a.setAttribute("download", "image.png")
        a.style.display = "none"
        document.body.appendChild(a)
        a.click()
        a.remove()


    }



        
                
    function copyToClipboard(data:string){
        navigator.clipboard.writeText(data)
    }

    return (
        <Grid.Container direction="column" css={{gap:"$5"}}>

            <Grid.Container justify="space-between" alignItems="center">
                <Grid css={{flex:"1 "}}>Copia Transaction ID<Text h5 small>{item.transaction_id}</Text></Grid>
                <Grid.Container css={{width:"auto", gap:"$5"}}>
                    <Button light auto color="primary" css={{padding: 0}} onClick={() => copyToClipboard(item.transaction_id)}><PaperPlus/></Button>
                </Grid.Container>
            </Grid.Container>

            <hr />

            <Grid.Container justify="space-between" alignItems="center">
                <Grid css={{flex:"1 "}}>Copia lo sku <Text h5 small>{item.sku}</Text></Grid>
                <Grid.Container css={{width:"auto", gap:"$5"}}>
                    <Button light auto color="primary" css={{padding: 0}} onClick={() => copyToClipboard(item.sku)}><PaperPlus/></Button>
                </Grid.Container>
            </Grid.Container>
            
            <hr />


            <Grid.Container justify="space-between" alignItems="center">
                <Grid css={{flex:"1 "}}>URI: </Grid>
                <Grid.Container css={{width:"auto", gap:"$5"}}>
                    <Text small>{item.item_data.uri}</Text>
                </Grid.Container>
            </Grid.Container>

                
            <Grid.Container justify="space-between" alignItems="center">
                <Grid css={{flex:"1 "}}>Transaction ID</Grid>
                <Grid.Container css={{width:"auto", gap:"$5"}}>
                    {item.transaction_id}
                </Grid.Container>
            </Grid.Container>

            <hr />

            <Grid.Container justify="space-between" alignItems="center">
                <Grid css={{flex:"1 "}}>Immagine Fronte</Grid>
                <Grid.Container css={{width:"auto", gap:"$5"}}>
                    <a href={item.item_data.front_url} target="_blank">
                        <Button light auto color="primary" css={{padding:0}} ><Show /></Button>
                    </a>
                    <Button light auto color="primary" css={{padding:0}} onClick={() => downloadUrl(item.item_data.front_url)}><Download/></Button>
                </Grid.Container>
            </Grid.Container>

            <Grid.Container justify="space-between" alignItems="center">
                <Grid css={{flex:"1 "}}>Immagine Retro</Grid>
                <Grid.Container css={{width:"auto", gap:"$5"}}>
                    <a href={item.item_data.back_url} target="_blank">
                        <Button light auto color="primary" css={{padding:0}} ><Show /></Button>
                    </a>
                    <Button light auto color="primary" css={{padding:0}} onClick={() => downloadUrl(item.item_data.back_url)}><Download/></Button>
                </Grid.Container>
            </Grid.Container>

            
            <hr />

            <Grid.Container justify="space-between" alignItems="center">
                <Grid css={{flex:"1 "}}>Ordine creato il </Grid>
                <Grid.Container css={{width:"auto", gap:"$5"}}>
                    <Text h5 css={{textAlign: "right"}}>{orderDate.date}, <br/> H {orderDate.hour}</Text>
                </Grid.Container>
            </Grid.Container>

            <Grid.Container justify="space-between" alignItems="center">
                <Grid css={{flex:"1 "}}>Email ordinante </Grid>
                <Grid.Container css={{width:"auto", gap:"$5"}}>
                    <Text h5>{item.owner_email}</Text>
                </Grid.Container>
            </Grid.Container>

        </Grid.Container>
    )
}