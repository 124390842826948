import { Button, Card, Grid, Text } from '@nextui-org/react'
import React from 'react'
import { Delete, PaperPlus, Plus, Show } from 'react-iconly'
import { useNavigate } from 'react-router-dom'
import Page, { ActionBar } from '../../components/page.component'
import { useCorp } from '../../contexts/corp.context'
import { useModal } from '../../contexts/modal.context'
import { useApp } from '../../contexts/state.context'

export default function Corps(){
    const navigate = useNavigate()
    const {selectCorp} = useCorp()
    const appState = useApp()
    const {corps} = appState.corps.state
    console.log(corps)
    const cardHeight = 150;
    

    return (
        <Page title="Seleziona una corporazione">
            <ActionBar actions={[
                { icon: <PaperPlus />, onClick:()=>navigate("/corps/nuova"), color: "success"}
            ]}/>
            <Grid.Container css={{gap: "$5", padding: "$5", height:"100%", overflowY: "auto"}}>
                {corps.map(corp => <CardComponent corp={corp} key={corp.id}/>)}
            </Grid.Container>
        </Page>
    )
}
function CardComponent(props:{corp: Corportation}){
    
    const {newModal, toggleModal} = useModal()
    const navigate = useNavigate()

    function showItemModal(){
        navigate(`/corps/${props.corp.id}`)
    }


    async function deleteItem(){
        try {
            
        } catch (error) {
            
        }
    }

        
    function showDeleteModal(){
        newModal({
            title: `Conferma eliminazione card `,
            body: <div>Eliminare Item?</div>,
            onConfirm:deleteItem,
            onCancel: toggleModal
        })
    }

    return (
            <Card variant='flat'>
                <Card.Header>Corp ID {props.corp.id}</Card.Header>
                <Card.Body>
                <Grid.Container direction="row" justify="space-between" alignItems="center">
                    <Text css={{flex: "1 0"}}>
                        <b>{props.corp.name}</b> | {props.corp.description }
                    </Text>
                    <Grid.Container css={{gap: "$5", flex: "0 1", flexWrap: "no-wrap"}}>
                        <Button auto light color="success" css={{gap: "$5", padding: 0}}onClick={showItemModal}>
                            <Show />
                        </Button>
                        <Button auto light color="error" onClick={showDeleteModal} css={{gap: "$5", padding: 0}}>
                            <Delete />
                        </Button>
                    </Grid.Container>
                </Grid.Container>
                </Card.Body>
            </Card>
        )
}

