import {initializeApp} from 'firebase/app'



const firebaseApp = initializeApp({
    apiKey: "AIzaSyDcimdNg7I4xSFv4w8aS7PuleRCUsjDM5Q",
    authDomain: "vcard-pininfarinasegno.firebaseapp.com",
    projectId: "vcard-pininfarinasegno",
    storageBucket: "vcard-pininfarinasegno.appspot.com",
    messagingSenderId: "291198345295",
    appId: "1:291198345295:web:1009ced978800e22804a3a",
    measurementId: "G-MZ7Y6GKGRS"
  })



export default firebaseApp