export function basePath() {
    if (window.STAGING === true) {
        return "https://api.staging.b1card.it"
    } else {
        return "https://api.b1card.it"
    }
}


export function stringToDate(dateRaw: string) {
    const date = new Date(dateRaw)
    const d = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    const hour = `${date.getHours}`
    return d
}

export function stringToFullDate(dateRaw: string) {
    const date = new Date(dateRaw)
    const d = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    const hour = `${date.getHours()}:${date.getMinutes()}`
    return {
        date: d,
        hour
    }
}

export function stringIsSku(str: string) {
    const firstChunk = str.split("-")[0]
    return !isNaN(Number(firstChunk))
}


export function fileToBase64(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function orderContainsString(order: Order, val: string) {
    if (order.sku.toLocaleLowerCase().includes(val))
        return true

    if (order.item_description.toLocaleLowerCase().includes(val))
        return true

    if (order.item_name.toLocaleLowerCase().includes(val))
        return true

    if (order.owner_email?.toLocaleLowerCase().includes(val))
        return true

    return false

}



