import {ReactNode} from "react";
import {Buy, Category, Filter, Home, Message, Document, Image2} from 'react-iconly'

export interface SidebarRouteProps{
    path: string,
    name: string,
    color?: string,
    relativePath?: boolean
}

export interface SidebarCategory{
    items: SidebarRouteProps[],
    name: string,

}

export interface SidebarTree{
    categories: SidebarCategory[]
}

export const sidebarTree:SidebarTree = {
    categories: [
        {
            name: "items",
            items: [
                {
                    path: "/unlinked-items",
                    name: "unlinked items",
                    relativePath: true

                },
                {
                    path: "/orders",
                    name: "ordini",
                    relativePath: true

                }
            ]

        },

        {
            name: "service",
            items:[
                {
                    path: "/corps",
                    name: "corps",
                    relativePath: true

                },
                {
                    path: "/members",
                    name: "membri",
                    relativePath: true

                },
            ]
        },
        {
            name: "utente",
            items:[
                // {
                //     path: "/",
                //     name: "impostazioni",
                //     relativePath: true
                // },
                {
                    path: "/logout",
                    name: "logout",
                    color: "error",
                    relativePath: true
                }
            ]
        }
    ]
}