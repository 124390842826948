import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { serviceRequest } from '../config/request.config'
import { isValidEmail } from '../helpers/input.helper'


interface IServiceMembersProps{

}


interface IServiceMembersState{
    members: Member[],
    loading: boolean
}

export default function useServiceMembers(props?:IServiceMembersProps){

    const [state, setState] = useState<IServiceMembersState>({
        members: [],
        loading: true
    })


    async function fetchMembers(){
        try {
            const {data} = await serviceRequest.get("/members")
            setState(oldState => ({...oldState, members: data, loading: false}))

        } catch (error) {
            console.log(error)
            setState(oldState => ({...oldState, members: [], loading: false}))
        }
    }


    async function deleteMember(member:Member){
        try {
            const {data} = await serviceRequest.delete(`/members/${member.uid}`)
            setState(oldState => ({
                ...oldState, members: oldState.members.filter(m => m.uid != member.uid)
            }))
        } catch (error) {
            alert("impossibile eliminare utente")
        }
    }

    async function addMember(email:string){
        try {

            if(!isValidEmail(email)) throw new Error("invalid email")
            

            const {data} = await serviceRequest.post("/members/", {
                email
            })

            return

        } catch (error) {
            console.log("ERROOOOR")
            alert(error)
        }
    }


    useEffect(() => {
        fetchMembers()
    }, [])
    return {
        ...state,
        setMembers:setState,
        deleteMember,
        addMember: addMember
    }
}