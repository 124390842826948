import React, {useEffect} from 'react'

import {Button, Grid, Input} from '@nextui-org/react'
import { useAuth } from '../../contexts/auth.context'
import { Navigate } from 'react-router-dom'


export default function Logout(){
    
    const {signout} = useAuth()

    useEffect(() => {
        signout()
    }, [])

    return (
        <Navigate to="/" />
    )
}
