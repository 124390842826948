import axios from 'axios'
import { basePath } from '../helpers/misc.helper'

const base = basePath()


export const request = axios.create({
    baseURL: base + "/v1",
})

export const serviceRequest = axios.create({
    baseURL: base + "/v1/service",
})


serviceRequest.defaults.withCredentials = true
serviceRequest.defaults.headers.post["Content-Type"] = "application/json"

request.defaults.withCredentials = true
request.defaults.headers.post["Content-Type"] = "application/json"


