import { Grid, Input, Dropdown, Text, Spacer, Switch, Button} from "@nextui-org/react";
import React, {useState, useEffect}from "react";
import Page, { ActionBar } from "../../components/page.component";
import useCorporations from "../../hooks/useCorps.hook";
import useCardTypes from "../../hooks/useCardTypes.hook";
import { serviceRequest } from "../../config/request.config";
import { useModal } from "../../contexts/modal.context";
import { useApp } from "../../contexts/state.context";
import { useFilePicker } from "use-file-picker";
import { fileToBase64 } from "../../helpers/misc.helper";
import { Delete } from "react-iconly";


export default function NewUnlinkedItem(props:any){
    const {newModal, toggleModal} = useModal()    
    const appState = useApp()
    const {corps} = appState.corps.state
    const [openFileSelector, {
        loading, filesContent, errors, plainFiles, clear
    }] = useFilePicker({
        multiple: false,
        accept: ".png, .svg"
    })
    const {patterns, types} = useCardTypes()
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [state, setState]= useState({
        cid: "-1",
        count: 1,
        backgroundColor: "#000000",
        contrastColor: "#FFFFFF",
        warranty:false,
        text: "",
        patternId: "-1",
        typeId: "-1"
    })
    
    const [colorState, setColorState] = useState("custom")
    
    const selectedCorp = corps.find(corp => corp.id == Number(state.cid)) ?? null
    const selectedPattern = patterns.find(pattern => pattern.id == Number(state.patternId)) ?? null
    const selectedType = types.find(type => type.id == Number(state.typeId)) ?? null
    const selectedColors = fetchColors()
                
    const canSubmit = (() => state.count > 0 && selectedType != null)()
    console.log("can submit,", canSubmit, selectedType)
    
    console.log("FILES",plainFiles)
        
                
    function fetchColors(){
        if(colorState === "custom"){
            return {
                backgroundColor: state.backgroundColor,
                contrastColor: state.contrastColor
            }
        }
        else if(colorState === "card"){
            return {
                backgroundColor: selectedType?.color ?? "",
                contrastColor: selectedType?.contrast_color ?? ""
            }
        }
        else if(colorState === "corp"){
            return {
                backgroundColor: selectedCorp?.background_color_hex ?? "",
                contrastColor: selectedCorp?.accent_color_hex ?? ""
            }
        } else {
            return {
                backgroundColor: "",
                contrastColor: ""
            }
        }
    }


    function handleFileSelection(){
        openFileSelector()
    }

    
    function handleInput(e:any){
        e.preventDefault()
        e.stopPropagation()
        setState(old => ({...old, [e.target.name]: e.target.value}))
    }
        
    async function submitData(){
        if(loadingSubmit) return
        
        const cid = Number(state.cid)
        const file = plainFiles.length === 1 ? await fileToBase64(plainFiles[0]) : null
        const data = {
            cid: cid > 0 ? cid : null,
            count: Number(state.count),
            custom_background_color_hex: selectedColors.backgroundColor,
            custom_contrast_color_hex: selectedColors.contrastColor,
            include_warranty: state.warranty,
            item_pattern_id: selectedPattern ? selectedPattern.id : null,
            item_type_id: selectedType ? selectedType.id : null,
            text: state.text.length ? state.text : null,
            logo: file
        }

        setLoadingSubmit(true)
                
        try{
            const resp = await serviceRequest.post("/unlinked-items", data)
            setLoadingSubmit(false)
            newModal({
                hideActions: true,
                title: "",
                body: <Text h4>Items creati con successo</Text>,
            })

        } catch(err){
            setLoadingSubmit(false)
        }
    }

    function handleCorpSelection(e:Set<string>){
        setState(oldState => ({...oldState, cid: e.anchorKey}))
    }
        
    function handleTypeSelection(e:Set<string>){
        setState(oldState => ({...oldState, typeId: e.anchorKey}))
    }
        
    function handlePatternSelection(e:Set<string>){
        setState(oldState => ({...oldState, patternId: e.anchorKey}))
    }
        
    function handleColorState(e:Set<string>){
        setColorState(e.anchorKey)
    }
    
        
    function handleSwitch(e){
        const checked = e.target.checked
        setState(oldState => ({...oldState, warranty: checked}))
    }

    return (
        <Page title="Nuovo unlinked-item">
            <ActionBar actions={[]}/>
            <Grid.Container css={{height:"100%", overflowY:"auto", padding: "$5", gap: "$5"}} justify="center">
                <Grid.Container css={{maxWidth:"500px", gap:"$10"}} direction="column">
                    <Grid>
                        <label style={{marginBottom: 10}}><Text weight={"normal"} h6>Seleziona una corporazione</Text></label>
                        <Dropdown>
                            <Dropdown.Button flat ghost>{!selectedCorp ? "Nessuna corporazione":selectedCorp.name}</Dropdown.Button>
                            <Dropdown.Menu selectionMode="single" selectedKeys={new Set([state.patternId])} onSelectionChange={handleCorpSelection}>
                                <Dropdown.Item key="-1">
                                    Nessuna corporazione
                                </Dropdown.Item>
                                {corps.map(corp => (
                                    <Dropdown.Item key={corp.id}>
                                        {corp.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Grid>

                    <Grid.Container justify={"space-between"}>
                        <Grid>
                            <Input value={state.count} width="100%" name="count" onChange={handleInput} label="numero unlinked items" type="number"/>
                        </Grid>
                        <Grid>
                            <Text>Garanzia</Text>
                            <Switch onChange={handleSwitch} checked={state.warranty}/>
                        </Grid>
                    </Grid.Container>
                                        
                    <Spacer/>
                                        
                    <Text h4>Personalizzazione Item</Text>
                    <Grid.Container justify={"space-between"} css={{gap: "$10"}}>
                        <Grid>
                            <div style={{border: "1px solid black", width: 20, height: 20, background: selectedColors.backgroundColor}} />
                            <Input value={selectedColors.backgroundColor} width="100%" name="backgroundColor" onChange={handleInput} disabled={colorState !== "custom"} label="colore di sfondo" />
                        </Grid>
                        <Grid>
                            <div style={{border: "1px solid black", width: 20, height: 20, background: selectedColors.contrastColor}} />
                            <Input value={selectedColors.contrastColor} width="100%" name="contrastColor" onChange={handleInput} disabled={colorState !== "custom"} label="colore di contrasto" />
                        </Grid>
                        <Grid>
                            <Input value={state.text} width="100%" name="text" onChange={handleInput} label={"testo card"} />
                        </Grid>
                    </Grid.Container>

                    <Grid.Container justify={"space-between"} css={{gap: "$10"}}>
                        <Grid css={{flex: 1}}>
                            <label style={{marginBottom: 10}}><Text weight={"normal"} h6>Seleziona la tipologia di card</Text></label>
                            <Dropdown>
                                <Dropdown.Button flat ghost>{!selectedType ? "Nessuna tipologia selezionata":selectedType.name}</Dropdown.Button>
                                <Dropdown.Menu selectionMode="single" selectedKeys={new Set([state.typeId])} onSelectionChange={handleTypeSelection}>
                                    <Dropdown.Item key="-1">
                                        Nessun tipologia selezionata
                                    </Dropdown.Item>
                                    {types.map(type=> (
                                        <Dropdown.Item key={type.id}>
                                            {type.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Grid>
                        <Grid>
                            <label style={{marginBottom: 10}}><Text weight={"normal"} h6>Seleziona un pattern</Text></label>
                            <Dropdown>
                                <Dropdown.Button flat ghost>{!selectedPattern ? "Nessun Pattern":selectedPattern.name}</Dropdown.Button>
                                <Dropdown.Menu selectionMode="single" selectedKeys={new Set([state.patternId])} onSelectionChange={handlePatternSelection}>
                                    <Dropdown.Item key="-1">
                                        Nessun pattern
                                    </Dropdown.Item>
                                    {patterns.map(pattern => (
                                        <Dropdown.Item key={pattern.id}>
                                            {pattern.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Grid>
                        <Grid>
                            <label style={{marginBottom: 10}}><Text weight={"normal"} h6>Selezionare la fonte del colore</Text></label>
                            <Dropdown>
                                <Dropdown.Button flat ghost>{colorState}</Dropdown.Button>
                                <Dropdown.Menu selectionMode="single" selectedKeys={new Set([colorState])} onSelectionChange={handleColorState}>
                                    <Dropdown.Item key="custom">
                                        colore custom
                                    </Dropdown.Item>
                                    <Dropdown.Item key="card">
                                        colore card
                                    </Dropdown.Item>
                                    { selectedCorp ? (
                                        <Dropdown.Item key="corp">
                                            colore corp
                                        </Dropdown.Item>
                                        )
                                    : ""}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Grid>

                    </Grid.Container>
                    <Spacer/>

                    <Grid.Container justify={"space-between"} css={{gap: "$10"}}>
                        <Button flat ghost onClick={handleFileSelection}>
                            {filesContent.length === 1 ? filesContent[0].name : "Seleziona Logo"}
                        </Button>
                        <Button light color={"error"} auto onClick={clear}>
                            <Delete />
                        </Button>
                    </Grid.Container>
                    <Spacer/>

                                        
                    <Grid css={{width: "100%"}}>
                        <Button css={{width:"100%"}} color="success" onPress={submitData} flat disabled={!canSubmit}>{!loadingSubmit ? `Aggiungi Unlinked ${state.count === 1 ? "Item" : "Items"}`: "Caricamento"}</Button>
                    </Grid>
                </Grid.Container>
                
            </Grid.Container>
        </Page>
    )
}
