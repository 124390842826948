import { Button, Modal, Text } from '@nextui-org/react'
import React, { createContext, useRef, useContext, useEffect, useState } from 'react'


declare interface IModalData{
    title: string,
    body: React.FC,
    onConfirm?: any,
    onCancel?: any,
    hideActions? :boolean
}

declare interface IModalContext{
    state:{
        visible: boolean
    },
    showModal: (title: string, body:string, onConfirm:any, onCancel:any) => void,
    newModal: (data:IModalData) => void,
    [key:string]:any
}


const modalContext = createContext<IModalContext>(null!)

export function useModal(){
    return useContext(modalContext) as IModalContext
}


export function ModalProvider(props){

    const [visible, setVisible] = useState(false)
    const [options, setOptions] = useState({
        title: "",
        body: "",
        onConfirm: undefined,
        onCancel: undefined,
        hideActions: false,
    })

    const modalBody = useRef()

    function toggleModal(val?:boolean){
        setVisible(oldState => {
            const state = val != undefined ? val : !oldState
            if(!state) modalBody.current = null

            console.log(state, val)
            return state
        })
    }


    function showModal(title: string, body:string, onConfirm:any, onCancel:any, hideActions=false){
        setVisible(true)
        setOptions({
            title, body, onCancel, onConfirm, hideActions
        })
    }
    
    
                
    function newModal(modalData:IModalData){
        const {title, body, onCancel, onConfirm, hideActions} = modalData
        setVisible(true)

        modalBody.current = body
        setOptions({
            title,
            body: "",
            onCancel: onCancel || null,
            onConfirm: onConfirm || null,
            hideActions: hideActions ?? false,
        })
    }


    const value:IModalContext = {
        state:{
            visible
        },
        showModal,
        toggleModal,
        newModal,
    }

    useEffect(() => {
    }, [visible])

    const ModalBody = modalBody.current ? () => modalBody.current : null
    return (
        <modalContext.Provider value={value}>
            {visible ? (
                <Modal open={visible} onClose={toggleModal}>
                    <Modal.Header>
                        <Text size={18}>{options.title}</Text>
                    </Modal.Header>
                    <Modal.Body>
                        {ModalBody != null ? <ModalBody/> : <Text>{options.body}</Text>}
                    </Modal.Body>
                    <Modal.Footer justify="center">
                        {options.hideActions ? null : (
                            <>
                                {!!options.onCancel && <Button auto light color='error' onClick={options.onCancel}>Chiudi</Button>}
                                
                                {!!options.onConfirm && <Button auto ghost color='success' onClick={options.onConfirm}>Conferma</Button>}
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
            ): null}
            {props.children}
        </modalContext.Provider>
    )
}

