import React, { useEffect, useRef, useState } from 'react'
import { serviceRequest } from '../config/request.config'


export default function useUnlinkedItems() {
    const [state, setState] = useState({
        unlinkedItems: [] as UnlinkedItem[],
        loading: true,
        cursor: 0,
        latestId: 0,
        direction: "right",
        idHistory: [0] as any as number[],
        cursorEnd: false,
    })



    const cursor = state.cursor
    const limit = 24



    async function fetchUnlinkedItems() {
        const { idHistory, direction } = state
        const idLength = idHistory.length

        setState(oldState => ({
            ...oldState,
            loading: false,
        }))

        // state.cursor



        // const latestId = direction === "left" ? idHistory[idLength - 2] : idHistory[idLength - 1]

        let idArray = [...state.idHistory]

        if (state.direction === "left") {
            idArray = idArray.filter((el, i) => i != cursor - 1)
        }
        console.log(state.direction)
        console.log("history: ", idArray)


        console.log("ID ARRAY", idArray.length)

        const latestId = idArray[cursor] ?? 0

        try {
            console.log("latest id: ", latestId)
            const { data }: { data: UnlinkedItem[] } = await serviceRequest(`/unlinked-items?cursor=${latestId ?? 0}&limit=${limit}`)


            const last = data[limit - 1]

            if (idArray.indexOf(last.id) > -1 && idArray.indexOf(last.id) < cursor) {
                idArray = idArray.slice(0, idArray.indexOf(last.id))
            }

            setState(oldState => ({
                ...oldState,
                unlinkedItems: data,
                idHistory: [...idArray, last.id]
            }))



            // const isCursorEnd = data.length < limit
            // let idArray = [...idHistory]

            // if (data.length && data.length === limit) {


            //     if (direction === "left") {
            //         const arr = idHistory.slice(0, cursor)
            //         if (arr.length == 0) arr.push(0)
            //         idArray = arr
            //     }

            //     if (direction === "right") {
            //         const lastElement = data[data.length - 1]
            //         const idArrayIndex = idArray.indexOf(lastElement.id)
            //         idArray.push(lastElement.id)
            //     }
            // }

            // setState(oldState => ({
            //     ...oldState,
            //     unlinkedItems: data,
            //     loading: false,
            //     cursor,
            //     cursorEnd: isCursorEnd,
            //     idHistory: idArray
            // }))

        } catch (err) {

            setState(oldState => ({
                ...oldState,
                loading: false,
                cursor,
            }))

        }


    }


    function updateCursorPosition(val: 1 | -1, direct: number | null = null) {
        if (direct) {

        }
        if (val === -1 && cursor === 0) return
        if (val === -1) {
            setState(oldState => ({ ...oldState, cursor: oldState.cursor - 1, direction: "left" }))
        } else if (val === 1) {
            setState(oldState => ({ ...oldState, cursor: oldState.cursor + 1, direction: "right" }))
        }

    }


    function handlePagination(i: number) {
        const index = i - 1
        updateCursorPosition(index > cursor ? 1 : -1)
    }


    useEffect(() => {
        console.log("cursor", state.cursor)
        fetchUnlinkedItems()
    }, [state.cursor])

    return {
        ...state,
        cursor: cursor,
        methods: {
            handlePagination
        }
    }

}