import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { request, serviceRequest } from '../config/request.config'

interface ICardTypeState {
    patterns: ItemPattern[],
    types: ItemType[],
    loading: boolean,
}


export default function useCardTypes() {
    const [state, setState] = useState<ICardTypeState>({
        patterns: [],
        types: [],
        loading: true
    })

    async function fetchPatterns() {
        try {
            const { data } = await request("/shop/items")
            // console.log(data)
            setState(oldState => ({
                ...oldState,
                patterns: data.items_patterns,
                types: data.item_types,
                loading: false
            }))
        } catch (err) {
            console.log(err)
            setState(oldState => ({
                ...oldState,
                loading: false
            }))
        }
    }


    useEffect(() => {
        fetchPatterns()
    }, [])

    return {
        ...state
    }
}