import React, { ContextType, FC } from "react"

import { useState, useEffect, useContext, createContext } from "react"
import firebaseApp from "../config/firebase"
import {Auth, getAuth, signInWithEmailAndPassword, signOut} from 'firebase/auth'
import { useNavigate } from "react-router-dom"
import { useAuth } from "./auth.context"
import {  makeServiceRequest } from "../config/axios.config"


export  interface CorpContext{
    // signin: (email:string, password:string) => void,
    // signout: () => Promise<void>,
    currentCorp: Corportation | null,
    selected: boolean,
    corps: Corportation[]
    loading: boolean,
    selectCorp: () => any
}


const corpContext = createContext<CorpContext>(null!)

export function useCorp(){
    const context = useContext(corpContext)
    return context
}


export const CorpProvider:FC = (props) => {

    const auth = useAuth()
    const token = auth.user.token
    const [loading, setLoading] = useState(true)
    const [selected, setSelected] = useState(false)
    const [corps, setCorps] = useState<Corportation[]>([])
    const [currentCorp, setCurrentCorp] = useState<Corportation | null>(null)
    const navigate = useNavigate()

    async function selectCorp(corp:Corportation){
        setCurrentCorp(corp)
        setSelected(true)
        navigate(`/corps/${corp.id}`)
    }


    async function makeReq(){
        const [data, err] = await makeServiceRequest<Corportation[]>("GET", "/corporations", token)
        setCorps(data)
    }



    useEffect(() => {
        if(token) makeReq()
    }, [token])
    

    const value = {
        loading, corps, currentCorp, selected, selectCorp
    }

    return <corpContext.Provider value={value}>
        {props.children}
    </corpContext.Provider>
        

}