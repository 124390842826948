import React, { FC } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import Layout from "../components/layout.component"
import { ProtectedRoute } from "../components/protected.route.component"
import { useCorp } from "../contexts/corp.context"
import CorpsRoutes from "./corps/corps.route"
import CorpDashboard from "./corps/dashboard.corp.route"
import Corps from "./corps/index.corps"
import Membri from "./corps/membri/membri.corps.route"
import NewCorp from "./corps/new.corp.route"
import Dashboard from "./dashboard/dashboard.route"
import Login from "./login/login.route"
import MembersRoute from "./members.route"
import OrdersRoute from "./orders.route"
import Home from "./unlinked/home.unlinked.route"
import NewUnlinkedItem from "./unlinked/new.unlinked.route"
import UnlinkedRoutes from "./unlinked/unlinked.route"


const AppRoutes:FC = (props) => {
    const corp = useCorp()
    const {pathname} = useLocation()
    return  <ProtectedRoute>
        <Routes>
                <Route path="/" element={<Navigate to="/orders"/>} />
                <Route path="/unlinked-items" >
                    <Route index element={<Home/>}/>
                    <Route path="nuovo" element={<NewUnlinkedItem />}/>
                </Route>

                <Route path="/corps">
                    <Route index element={<Corps />} />
                    <Route path="nuova" element={<NewCorp />} />
                    <Route path=":cid" element={<CorpDashboard />} />

                </Route>
                                
                <Route path="/orders" element={<OrdersRoute />} />
                <Route path="/members" element={<MembersRoute />} />
        </Routes>
    </ProtectedRoute>

} 

export default AppRoutes