import React from "react"
import { FC } from "react"
import { Navigate, useLocation, } from "react-router-dom"
import { useAuth } from "../contexts/auth.context"


declare interface ProtectedRouteProps{
    children: any
}



export const ProtectedRoute:FC = ({children}) => {
    const {loading, logged} = useAuth()
    const {pathname} = useLocation()
    if(!logged && pathname != "/login"){
        return <Navigate to="/login" replace />
    }


    return children

}