import { Button, Card, Grid, Input, Pagination, Text } from '@nextui-org/react'
import React, { useEffect, useRef } from 'react'
import { ChevronRight, Delete, Download, PaperPlus, Search, Show } from 'react-iconly'
import { useNavigate } from 'react-router-dom'
import Page, { ActionBar } from '../../components/page.component'
import { useModal } from '../../contexts/modal.context'
import { useApp } from '../../contexts/state.context'
import { stringToFullDate } from '../../helpers/misc.helper'


export default function Home(props) {
  const { page, pageMethods, unlinked } = useApp()
  const { unlinkedItems, cursor, cursorEnd, idHistory, loading } = unlinked.state
  const { handlePagination } = unlinked.methods
  const searchValue = ""
  const navigate = useNavigate()

  const paginationIndex = cursor + 1




  function handleUserSearch(e: string) {
    // console.log("change", state)

    // console.log(cursor)
    methods.updateSearchValue(e)

  }


  useEffect(() => {
    pageMethods.setPageTitle("Unlinked Items")
  }, [])

  return (
    <Page title={page.name}>
      <ActionBar actions={[
        { icon: <PaperPlus />, onClick: () => navigate("/unlinked-items/nuovo"), color: "success" }
      ]}
      // middle={() => <InputSearchBar  onSearch={handleUserSearch} value={searchValue} />}
      />
      <Grid.Container css={{ height: "100%", overflowY: "auto", padding: "$5", gap: "$5" }}>
        {unlinkedItems.map((item, i) => <CardComponent key={`unl-${i}`} item={item} />)}
      </Grid.Container>
      <Grid.Container css={{ marginTop: "$10", gap: "$10", alignItems: 'center', justifyContent: "center" }}>
        <Pagination color="secondary" noMargin initialPage={paginationIndex} controls onChange={handlePagination} total={cursorEnd ? paginationIndex : paginationIndex + 1} />
      </Grid.Container>
    </Page>
  )
}

interface InputSearchBarProps {
  onSearch: (e: string) => void,
  value: string
}

function InputSearchBar(props: InputSearchBarProps) {
  const ref = useRef<HTMLElement>(null!)


  function filterElements() {
    props.onSearch(ref.current.value)
  }

  return (
    <Grid.Container css={{ width: "100%", gap: "$10" }}>
      <Grid css={{ flex: 1 }}>
        <Input ref={ref} width="100%" aria-label="Filtra per sku, email, transaction id o tipologia prodotto" placeholder="Filtra per sku, email o tipologia prodotto" />
      </Grid>
      <Grid>
        <Button auto light onClick={filterElements}><Search style={{ marginRight: 10 }} size={"small"} /> Cerca </Button>
      </Grid>
    </Grid.Container>
  )
}


interface CardComponentProps {
  item: UnlinkedItem
}

function CardComponent(props: CardComponentProps) {

  const { newModal, toggleModal } = useModal()
  const itemDate = stringToFullDate(props.item.created_at)

  function showItemModal() {
    newModal({
      title: `Dettagli card ${props.item.serial_id}`,
      body: <ModalBody item={props.item} />,
      onCancel: () => toggleModal(false)
    })
  }


  async function deleteItem() {
    try {

    } catch (error) {

    }
  }


  function showDeleteModal() {
    newModal({
      title: `Conferma eliminazione card `,
      body: <div>Eliminare Item?</div>,
      onConfirm: deleteItem,
      onCancel: () => toggleModal(false)
    })
  }

  return (
    <Card variant='flat'>
      <Card.Header>{props.item.uri} - {props.item.id}</Card.Header>
      <Card.Body>
        <Grid.Container direction="row" justify="space-between" alignItems="center">
          <Grid css={{ flex: "1 0" }}>
            SERIAL ID: <b>{props.item.serial_id!}</b> | {props.item.shipped ? "SPEDITA" : "DA SPEDIRE"}
            <br /><Text>creato il <b>{itemDate.date}, ore {itemDate.hour}</b></Text>
          </Grid>
          <Grid.Container css={{ gap: "$5", flex: "0 1", flexWrap: "no-wrap" }}>
            <Button auto light color="success" css={{ gap: "$5", padding: 0 }} onClick={showItemModal}>
              <Show />
            </Button>
            <Button auto light color="error" onClick={showDeleteModal} css={{ gap: "$5", padding: 0 }}>
              <Delete />
            </Button>
          </Grid.Container>
        </Grid.Container>
      </Card.Body>
    </Card>
  )
}


function ModalBody(props: { item: UnlinkedItem }) {
  const { item } = props
  const itemDate = stringToFullDate(props.item.created_at)

  function downloadUrl(url: string) {

    const u = new URL(url)
    const imagePath = url + "?attachment=true"
    // const image = await saveAs(imagePath, "image.png")

    const a = document.createElement("a")
    a.setAttribute("href", imagePath)
    a.setAttribute("download", "image.png")
    a.style.display = "none"
    document.body.appendChild(a)

    a.click()
    a.remove()

  }


  function copyToClipboard() {
    navigator.clipboard.writeText(item.uri)
  }

  return (
    <Grid.Container direction="column" css={{ gap: "$5" }}>

      <Grid.Container justify="space-between" alignItems="center">
        <Grid css={{ flex: "1 " }}>Copia il link</Grid>
        <Grid.Container css={{ width: "auto", gap: "$5" }}>
          <Button light auto color="primary" css={{ padding: 0 }} onClick={copyToClipboard}><PaperPlus /></Button>
        </Grid.Container>
      </Grid.Container>

      <Grid.Container justify="space-between" alignItems="center">
        <Grid css={{ flex: "1 " }}><Text h5 small>{item.uri}</Text></Grid>
      </Grid.Container>

      <hr />

      <Grid.Container justify="space-between" alignItems="center">
        <Grid css={{ flex: "1 " }}>Corporation ID</Grid>
        <Grid.Container css={{ width: "auto", gap: "$5" }}>
          {item.corporation_id ?? "none"}
        </Grid.Container>
      </Grid.Container>

      <hr />

      <Grid.Container justify="space-between" alignItems="center">
        <Grid css={{ flex: "1 " }}>Immagine Fronte</Grid>
        <Grid.Container css={{ width: "auto", gap: "$5" }}>
          <a href={item.image_url_front} target="_blank">
            <Button light auto color="primary" css={{ padding: 0 }} ><Show /></Button>
          </a>
          <Button light auto color="primary" css={{ padding: 0 }} onClick={() => downloadUrl(item.image_url_front)}><Download /></Button>
        </Grid.Container>
      </Grid.Container>

      <Grid.Container justify="space-between" alignItems="center">
        <Grid css={{ flex: "1 " }}>Immagine Retro</Grid>
        <Grid.Container css={{ width: "auto", gap: "$5" }}>
          <a href={item.image_url_rear} target="_blank">
            <Button light auto color="primary" css={{ padding: 0 }} ><Show /></Button>
          </a>
          <Button light auto color="primary" css={{ padding: 0 }} onClick={() => downloadUrl(item.image_url_rear)}><Download /></Button>
        </Grid.Container>
      </Grid.Container>


      <hr />

      <Grid.Container justify="space-between" alignItems="center">
        <Grid css={{ flex: "1 " }}>Data Creazione</Grid>
        <Grid.Container css={{ width: "auto", gap: "$5", textAlign: "right" }}>
          {itemDate.date}<br /> H {itemDate.hour}
        </Grid.Container>
      </Grid.Container>

      <Grid.Container justify="space-between" alignItems="center">
        <Grid css={{ flex: "1 " }}>Spedita</Grid>
        <Grid.Container css={{ width: "auto", gap: "$5" }}>
          {String(item.shipped)}
        </Grid.Container>
      </Grid.Container>

    </Grid.Container>
  )
}