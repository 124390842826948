
import { Button, Card, Grid ,Text } from "@nextui-org/react";
import React from "react";


interface ListProps{
    elements: IListElement[]
}

declare interface IListElement{
    key: any,
    value: string | number,
    actions: Action[],
    label?:string,
    data?:any,
}


export default function List(props:ListProps){

    const {elements} = props

    return (
        <Grid.Container direction="column" css={{gap: "$5", padding: "$5", overflowY:"auto", height: "100%", flexWrap: "no-wrap"}}>
            {elements.map((el, i) => <ListElement  {...el}/>)}
        </Grid.Container>
    )
}



// function ListElement(props:IListElement){
//     return (
//         <Card shadow={false} bordered>
//             <Grid.Container direction="row" wrap="nowrap">
//                 <Grid.Container direction="column" css={{flexGrow: 1, justifyContent:"center"}}>
//                     <Grid>
//                         {props.label}
//                     </Grid>
//                     <Grid>
//                         {props.value}
//                     </Grid>
//                 </Grid.Container>
//                 <Grid.Container css={{flex: 0, alignItems: "center", flexWrap:"noWrap"}}>
//                     {props.actions.map(action => (
//                         <Button auto key={`${action.value}`} light color={action.color} onClick={() => action.onClick(props.data)} css={{gap: "$5"}}>
//                             {action.icon} <Text color={action.color}>  {action.value}</Text>
//                         </Button>
//                     ))}
//                 </Grid.Container>
//             </Grid.Container>
//         </Card>
//     )
// }



function ListElement(props:IListElement){
    return (
        <Card variant="flat" css={{flexShrink: 0}}>
            <Card.Header>{props.label}</Card.Header>
            <Card.Body>
                <Grid.Container direction="row" justify="space-between" alignItems="center">
                    <Grid css={{flex: "1 0"}}>
                        {props.value}
                    </Grid>
                    <Grid.Container css={{gap:"$5", flex: "0 1", flexWrap: "no-wrap"}}>
                        {props.actions.map((action, i) => (
                            <Button auto key={`list-${i}`} light color={action.color} css={{gap: "$5", padding: "0"}} onClick={() => action.onClick(props.data)}>
                                {action.icon}
                            </Button>
                        ))}
                    </Grid.Container>
                </Grid.Container>
            </Card.Body>
        </Card>
    )
}
