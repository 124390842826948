import { FormElement } from '@nextui-org/react'
import axios from 'axios'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { serviceRequest } from '../config/request.config'


interface IOrdersProps{

}


interface IOrdersState{
    orders: Order[],
    loading: boolean,
    searchValue: string
}

export default function useOrders(props?:IOrdersProps){

    const [state, setState] = useState<IOrdersState>({
        orders: [],
        loading: true,
        searchValue: ""
    })


    async function fetchOrders(){
        try {
            const {data} = await serviceRequest.get("/orders/items")
            console.log(data)
            setState(oldState => ({...oldState, orders: data, loading: false}))

        } catch (error) {
            console.log(error)
            setState(oldState => ({...oldState, orders: [], loading: false}))
        }
    }

    async function deleteOrder(){
        
    }

    function updateSearchValue(val:string){
        setState(oldState => ({
            ...state,
            searchValue: val
        }))
        
    }



    useEffect(() => {
        fetchOrders()
    }, [])
    return {
        ...state,
        setOrders:setState,
        deleteOrder,
        updateSearchValue
    }
}