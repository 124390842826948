import axios from 'axios'
import React, {createContext, FC, useContext, useEffect, useState} from 'react'
import useFetch from 'use-http'
import { makeServiceRequest } from '../config/axios.config'
import useCorporations from '../hooks/useCorps.hook'
import useOrders from '../hooks/useOrders.hook'
import useServiceMembers from '../hooks/useServiceMembers.hook'
import useUnlinkedItems from '../hooks/useUnlinkedItems.hook'
import { useAuth } from './auth.context'




export interface AppStateContext{
    corps: {
            state: ICorpsState,
            methods:{
                [key:string]:any
            }
        }
    unlinked: {
        state:AppState,
        methods: {
            [key:string]:any
        }
    },
    serviceMembers: {
        state: Member[],
        methods:{
            deleteMember: (member:Member) => Promise<void>,
            addMember: (email:string) => Promise<void>
        }
    },
    orders:{
        searchValue: string
        state:Order[],
        methods: {[key:string]:any}
    }

    page: PageStateInterface,
    pageMethods: AppPageMethods
}

export interface AppState{
    loading: boolean,
    unlinkedItems: UnlinkedItem[],
    cursor:number
}

interface PageStateInterface{
    name: string
}



const appContext = createContext<AppStateContext>(null!)

export function useApp(){
    return useContext(appContext) as AppStateContext
}

export function AppProvider(props){

    const auth = useAuth()
    const [page, setPage] = useState<PageStateInterface>({
        name :"",
    })
    const {corps, getCorpById, loading:corpsLoading} = useCorporations()
    const {members, loading, setMembers, deleteMember, addMember} = useServiceMembers()
    const {orders, updateSearchValue, searchValue} = useOrders()

    // const [state, setState] = useState<AppState>({
    //     loading: false,
    //     unlinkedItems: [] as UnlinkedItem[],
    // })

    const {unlinkedItems, loading:unlinkedLoading, cursor, methods, cursorEnd, idHistory} = useUnlinkedItems()


    const pageMethods = {
        setPageTitle: (name: string) => setPage(old => ({...old, name}))
    }


    const value:AppStateContext = {
        page,
        corps:{
            state:{
                loading: corpsLoading,
                corps
            },
            methods:{
                getCorpById
            }
        },
        pageMethods,
        serviceMembers:{
            state: members,
            methods: {
                deleteMember,
                addMember
            }
        },
        orders:{
            state:orders,
            searchValue,
            methods: {
                updateSearchValue
            }
        },
        unlinked: {
            state: {
                loading,
                unlinkedItems,
                cursor,
                idHistory,
                cursorEnd
            },
            methods
        }
    }


    async function fetchItems(refresh=false){
        //const data = await req.get("/unlinked-items")
        // if(!refresh && state.unlinkedItems.length) return
        // const [data,err] = await makeServiceRequest<UnlinkedItem[]>("GET", "/unlinked-items", auth.user.token)
        // console.log(data, err)
        // if(!err) setState(oldState => ({...oldState, unlinkedItems: data}))

    }

    useEffect(() => {
        fetchItems()
        
    }, [])

    return <appContext.Provider value={value}>
        {unlinkedLoading ? "loading app" : props.children}
    </appContext.Provider>
}