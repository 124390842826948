import { Button, Grid, Input } from "@nextui-org/react";
import axios from "axios";
import React, { useState } from "react";
import { FC } from "react";
import Page from "../../components/page.component";
import { useAuth } from "../../contexts/auth.context";

const Login: FC = (props) => {
    const authContext = useAuth()

    const [loginState, updateLoginState] = useState({
        email: "",
        password: ""
    })

    const handleChange = (e: any) => {
        updateLoginState({
            ...loginState,
            [e.target.name]: e.target.value
        })
    }


    const handleLogin = (e: any) => {
        const { email, password } = loginState
        authContext.signin(email, password)
    }

    const handleLog = (e: any) => {
        console.log(authContext)
    }

    const handleTest = async (e: any) => {
        try {
            const a = await axios.get("https://api.staging.b1card.it/v1/service/corporations", {
                headers: {
                    "Authorization": `Bearer ${authContext.user.token}`
                }
            })
            console.log(a)

        } catch (error) {
            console.log("errror")
            console.log(error)
        }
    }
    return (
        <Page>
            <Grid.Container direction="column" justify="center" alignContent="center">
                <Grid sm={6} direction="column" css={{marginTop: "200px"}}>
                    <Input value={loginState.email} name='email' onChange={handleChange} width='200px' labelPlaceholder='email' css={{ width: "100%" }} />
                    <Input value={loginState.password} type='password' onChange={handleChange} name='password' width='200px' css={{ mt: 30, width: "100%" }} labelPlaceholder='password' />
                    <Button onClick={handleLogin} size="sm" css={{ mt: 30 }}>Login</Button>
                </Grid>
            </Grid.Container>
        </Page>
    )
}

export default Login