import { createTheme,  NextUIProvider } from '@nextui-org/react'
import React, { FC } from 'react'
import './config/firebase'
import Layout from './components/layout.component'
import { Route, BrowserRouter, Routes,  } from 'react-router-dom'
import Login from './routes/login/login.route'
import { AuthProvider } from './contexts/auth.context'
import { CorpProvider } from './contexts/corp.context'
import AppRoutes from './routes/routes'
import { AppProvider } from './contexts/state.context'
import { ModalProvider } from './contexts/modal.context'
import Logout from './routes/login/logout.route'

const App: FC = () => {



      

    return (
        <NextUIProvider >
        <BrowserRouter>
            <AuthProvider>
                <AppProvider>
                <CorpProvider>
                    <ModalProvider>
                        <Layout>
                            <Routes>
                                <Route path="/*" element={<AppRoutes />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/logout" element={<Logout />} /> 
                            </Routes>
                        </Layout>
                    </ModalProvider>
                </CorpProvider>
                </AppProvider>
            </AuthProvider>
        </BrowserRouter>
    </NextUIProvider>
    )
}

export default App