import { Button, Card, Container, Divider, Grid, Text} from '@nextui-org/react'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom';
import { SidebarCategory, sidebarTree } from '../config/sidebar.config';
import { useAuth } from '../contexts/auth.context';
import { useCorp } from '../contexts/corp.context';
import Header from './header.component';
import Sidebar from './sidebar.component';


declare interface LayoutProps{

}


const Layout:FC<LayoutProps> = (props) => {
    
    const auth = useAuth()
    const corp = useCorp()
    const headerHeight = auth.logged ? 50 : 0;
    const contentWidth = auth.logged && corp.selected ? 9 : 12


    return     <Grid.Container  css={{height: "100%"}}>
    <Grid.Container css={{ height: `100%`}} alignContent='stretch' direction='row' style={{padding: 20}}>
        {/* <Sidebar  tree={sidebarTree} version='0.5.0'/> */}
        <Grid.Container css={{height: "100%", width: "200px", marginRight: 20 }}  direction="column">
            {sidebarTree.categories.map((cat, i) => (
                <SidebarCard category={cat} key={`sc-${i}`} i={i}/>
            )) }
        </Grid.Container>
        <Grid.Container css={{flex: 1, height:"100%"}}>
            <Text>{}</Text>
            <Grid.Container css={{width: "100%", height:"100%"}} >
                <Card css={{height: "100%"}}>
                    <Grid.Container direction="column" css={{height:"100%"}}>
                    {props.children}
                    </Grid.Container>        
                </Card>
            </Grid.Container>
        </Grid.Container>
    </Grid.Container>
</Grid.Container>
}



interface SidebarCardProps{
    category: SidebarCategory
}


function SidebarCard(props:any){
    const {category} = props
    const navigator = useNavigate()
    function navigate(item:SidebarCategory){
        navigator(item.path)
    }



    return (
            <Grid.Container direction='column' gap={1} >
        <Card css={{marginBottom: 10}}>
                <Grid>
                    <Text css={{textAlign:"center"}} size="small" >{category.name}</Text>
                </Grid>
                {category.items.map((item, i) => <Grid css={{display: "flex", justifyContent:"center"}}>
                    <Button light size={"sm"} color={item.color ?? "default"} onPress={() => navigate(item)} key={`btn-${i}-${props.i}`}>{item.name}</Button>
                </Grid>)}
                
        </Card>
            </Grid.Container>
    )
}




export default Layout