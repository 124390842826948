import React from 'react'
import { PaperPlus } from 'react-iconly'
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import Page, { ActionBar } from '../../components/page.component'
import { useApp } from '../../contexts/state.context'

export default function CorpDashboard(props:any){
    const {cid} = useParams()
    const navigate = useNavigate()
    const {state:{corps}, methods:{getCorpById}} = useApp().corps
    const corp = getCorpById(cid) as Corportation
    return (
        <Page title={corp.name}>
            <ActionBar actions={[
                { icon: <PaperPlus />, onClick:()=>navigate("/corps/nuova"), color: "success"}
            ]}/>

        </Page>
        )
}
