import App from './src/app'
import {render} from 'react-dom'
import { AuthProvider } from './src/contexts/auth.context'
import React from 'react'




render(
    <App />,
    document.getElementById("root")
)