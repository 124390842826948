import { Button, Grid, Input, Text } from '@nextui-org/react'
import React, { useState } from 'react'
import Page, { ActionBar } from '../../components/page.component'
import { useModal } from '../../contexts/modal.context'
import { isValidEmail } from '../../helpers/input.helper'
import useCorporations from '../../hooks/useCorps.hook'


export default function NewCorp(){
    
    const {createCorp} = useCorporations()
    const {newModal} = useModal()
        
    const [state, setState] = useState({
        email: "",
        name: "",
        loading: false
    })
    
    function handleInputChange(e:any){
        setState(oldState => ({...oldState, [e.target.name]:e.target.value}))
    }
        
    async function submit(){
        setState(oldState => ({...oldState, loading: true}))
        try{
            const data = await createCorp(state.email, state.name)
            newModal({
                title:"",
                body:  <Text h4>Corp creata con successo</Text>,
                hideActions: true,
                
            })
            setState(oldState => ({...oldState, loading: false, email: "", name: ""}))


        } catch(err){
            newModal({
                title:"",
                body:  <Text h4>Impossibile creare Corp</Text>,
                hideActions: true,
                
            })
            setState(oldState => ({...oldState, loading: false}))
        }
    }
    

    const isDisabled = !(isValidEmail(state.email) && state.name.length > 3) || state.loading
    console.log(state.loading)
    return (

        <Page title="Nuova Corporazione">
            <ActionBar actions={[]}/>
            <Grid.Container direction="column" css={{height: "100%", overflowY: "auto", maxWidth: 500, gap: "$10", margin: "auto"}} >
                <Input value={state.email} name="email" onChange={handleInputChange} label="Email Amministratore Corp"/>
                <Input value={state.name} name="name" onChange={handleInputChange} label="Corp name"/>
                <Button onPress={submit} color="success" flat css={{marginTop: "$10"}} disabled={isDisabled}>Crea Corp</Button>
            </Grid.Container>
        </Page>
    )
}
