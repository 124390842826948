import { Button, Grid, Spacer, Text } from '@nextui-org/react'
import React from 'react'
import { ArrowLeft } from 'react-iconly'
import { useNavigate } from 'react-router-dom'

interface PageProps{
    children: any
    title: string,
    actions?: Action[]
}


export default function Page(props:PageProps){
    return (
        <Grid.Container direction='column' css={{flex: 1, flexWrap: "no-wrap",height: "100%", padding: "$10", }}>

            <Grid>
                <Text>{props.title}</Text>
            </Grid>
            {props.actions ? <ActionBar actions={props.actions} /> : null }
            {props.children}
        </Grid.Container>
    )
}


interface ActionBarProps{
    actions:Action[],
    middle?:React.FC
}



export function ActionBar(props:ActionBarProps){
    const navigate = useNavigate()
    function goBack(){
        navigate(-1)
    }


    return (<Grid.Container css={{padding: "$10 0", gap: "$10"}} justify="space-between">
        <Grid css={{flex: 0}}>
            <Button auto flat color={"secondary"} onClick={goBack}><ArrowLeft /></Button>
        </Grid>
        <Grid.Container css={{flex: "1", alignItems:"center"}}>
            {props.middle ? <props.middle /> : null}
        </Grid.Container>
        <Grid>
            {props.actions.map((action, i)=> <Button key={`a-${i}`} auto flat color={action.color} onClick={action.onClick}>{action.icon} <Text color={action.color}>  {action.value}</Text></Button>)}
        </Grid>
        
    </Grid.Container>)
}


