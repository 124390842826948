import { Input, Grid, Text, Button } from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { Delete, PaperPlus } from "react-iconly";
import List from "../components/list.component";
import Page, { ActionBar } from "../components/page.component";
import { useModal } from "../contexts/modal.context";
import { useApp } from "../contexts/state.context";
import useServiceMembers from "../hooks/useServiceMembers.hook";

export default function MembersRoute() {

  const { serviceMembers } = useApp()
  const { state, methods } = serviceMembers


  const { toggleModal, showModal, newModal } = useModal()

  const pageActions = [
    {
      onClick: addUser,
      icon: <PaperPlus />,
      color: "success"
    }
  ]


  const listElements = state.map((member, i) => ({
    value: `${member.display_name}`,
    label: member.email,
    data: member,
    key: `l-${i}`,
    actions: [{
      onClick: onUserDelete,
      icon: <Delete />,
      color: "error"
    }]
  }))

  async function handleNewMember(email:string){
    try {
      await methods.addMember(email)
      // toggleModal(false)
      return true
    } catch(err) {
      return false
    }
  }


  function addUser() {
    // showModal("aggiungere utente?", "" , () => {}, toggleModal)

    newModal({
      title: "Aggiungi utente service",
      body: <NewUserModal currentMembers={serviceMembers.state} onClick={handleNewMember} onClose={() => toggleModal(false)} />,
      hideActions: true,

    })

  }

  function onUserDelete(data: Member) {
    console.log("DATA", data)
    showModal("Attenzione!", `Eliminare ${data.display_name} - ${data.email} ? `, async () => {
      await methods.deleteMember(data)
      toggleModal()
    }, toggleModal)
  }

  return (
    <Page title="Prova" actions={pageActions}>
      <List elements={listElements} />
    </Page>
  )
}



function NewUserModal(props: { onClick: any, currentMembers: Member[], onClose: any }) {
  const [state, setState] = useState("")
  const [status, setStatus] = useState({
    type: "default",
    message: "",
    disabled: false
  })

  const ref = useRef(null)

  function onPress() {
    const foundMember = props.currentMembers.find((member) => member.email === state)

    if(status.type != "default" && !foundMember) {

      setStatus({
        type: "default",
        message: "",
        disabled: false
      })
    }

    props.onClick(state)
      .then(res => {
        setStatus({
          type: "success",
          message: "Utente creato con successo",
          disabled: true
          })
        })
      .catch(err => {
        setStatus({
          type: "error",
          message: "Impossibile creare utente",
          disabled: false
          })
      })

    
  }

  useEffect(() => {
    const foundMember = props.currentMembers.find((member) => member.email === state)
    if (foundMember) {

      setStatus({
        type: "error",
        message: "Email già presente",
        disabled: true
      })

      return 
    }
    
    if(status.type != "default" && !foundMember) {

      setStatus({
        type: "default",
        message: "",
        disabled: false
      })
    }

  }, [state])

  useEffect(()=> {
    ref.current.focus()
  }, [status.type])

  return (
    <Grid.Container justify="center">
      <Text css={{ marginBottom: "$5" }}>Inserisci l'email</Text>
      <Input ref={ref} status={status.type} label={status.message} value={state} onChange={(e) => setState(e.target.value)} width="100%" />

      <Button color="error" light auto css={{ marginTop: "$10" }} onClick={props.onClose}>Chiudi</Button>
      <Button disabled={status.disabled} color="success" ghost auto css={{ marginTop: "$10" }} onClick={onPress}>Aggiungi</Button>

    </Grid.Container>
  )
}