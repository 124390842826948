import React, { ContextType, FC } from "react"

import { useState, useEffect, useContext, createContext } from "react"
import firebaseApp from "../config/firebase"
import {Auth, browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword, signOut} from 'firebase/auth'
import { useNavigate } from "react-router-dom"
import Login from "../routes/login/login.route"
import { request, serviceRequest } from "../config/request.config"
import axios from "axios"


export  interface IAuthContext{
    signin: (email:string, password:string) => void,
    signout: () => Promise<void>,

    auth: Auth,
    user: IAuthUser,
    loading: boolean,
    logged:boolean
}

export interface IAuthUser{
    email: string,
    token: string,
    uid: string
}

const auth = getAuth(firebaseApp)

export const AuthContext = createContext<IAuthContext>(null!)

export function useAuth(){
    const context = useContext(AuthContext) as any as IAuthContext
    return context
}


export const AuthProvider:FC = (props) => {
    const [user, setUser] = useState<IAuthUser>({
        email: "",
        token: "",
        uid: ""
    })

    const [loading, setLoading] = useState(true)
    const [logged, setLogged] = useState(false)
    const value = {
        signin, auth, user, loading, logged, signout
    }

    const navigate = useNavigate()

    async function signin( email:string, password:string){
        setLoading(true)
        await setPersistence(auth, browserSessionPersistence)
        await signInWithEmailAndPassword(auth, email, password)
    }

    async function signout(){
        setLoading(true)
        await signOut(auth)
        setLoading(false)
        setLogged(false)
    }

    useEffect(() => {

        const unsubscriber = auth.onAuthStateChanged(async user => {
            if(user && user.email){
                const token = await user.getIdToken()
                setUser({
                    email: user.email,
                    token: token,
                    uid: user.uid
                })

                //IMPOSTANDO HEADERS PER CONFIG

                request.defaults.headers.common["Authorization"] = `Bearer ${token}`
                serviceRequest.defaults.headers.common["Authorization"] = `Bearer ${token}`


                
                setLoading(false)
                setLogged(true)
                return 
            } else {
                setLoading(false)
                return
                //navigate("/login")
            }
        })
        return unsubscriber

    }, [])

    return (
        <AuthContext.Provider value={value}>
            {logged ? props.children : <Login />}
        </AuthContext.Provider>
    )
}