import axios, {AxiosResponse, AxiosError, Method, Axios} from "axios";
import { useState, useEffect } from 'react';
import { basePath } from "../helpers/misc.helper";

interface AxiosIntanceProps{
    endPoint:string,
    headers?:{[key:string]:any}
    data?:any,
}

type AxiosCustomReponse<T> = [T, boolean]

const createAxiosInstance = (props:AxiosIntanceProps) => {
    const {endPoint} = props
    const data = props.data ?? null
    return axios.create({
        baseURL: `${basePath()}/v1/${endPoint}`,
        timeout: 1000,
        headers:{
            ...props.headers
        },
        data
    })
}


export const makeServiceRequest = async <T>(method:Method, path: string, token: string, data?:any):Promise<AxiosCustomReponse<T>> => {
    const req = createAxiosInstance({
        endPoint: "service",
        headers: {
            "Authorization": `Bearer ${token}`
        },
    })
    try {
        const result = (await req.request({
            method,
            data,
            url: path
        })).data as T;
        return [result , false]
    } catch (error) {
        return [null, error]
    }
} 


export const makeCorpRequest = async <T>(method:Method, path: string, token: string, data?:any):Promise<AxiosCustomReponse<T>> => {
    const req = createAxiosInstance({
        endPoint: "corporations",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    try {
        const result = (await req.request({
            method, data, url: path
        })).data as T
        return [result, false]
    } catch (error) {
        return [null, error]
    }
}